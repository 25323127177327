<template>
    <div class="internal-sections">
        <div class="internal-sections-top" style="margin-bottom: 10px">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Аризалар</div>
                        <crm-refresh @c-click="refresh()"></crm-refresh>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <el-button class="my-title-btn-of"  @click="drawerOpen = true">
                        <svg class="icon-flter" viewBox="0 0 512 512">
                            <g>
                                <g>
                                    <path d="M8.959,0v81.89l201.666,201.666V512l90.75-60.5V283.556L503.041,81.89V0H8.959z M271.125,435.31l-30.25,20.167V292.416
                                        h30.25V435.31z M279.985,262.166h-47.969L60.6,90.75h390.802L279.985,262.166z M472.791,60.5H39.209V30.25h433.582V60.5z"/>
                                </g>
                            </g>
                        </svg>

                    </el-button>
                </el-col>
            </el-row>
        </div>

        <div class="applications-links">
            <router-link :to="{ name: 'allApplications' }">
                Arizalar
                <div class="price">{{ all_applications }}</div>
            </router-link>
            <router-link to="#" @click.native.capture.stop>  <!-- :to="{ name: 'processFillingApplications' }"params:{filters: filter} -->
                Ma'lumot to'ldirish jarayonida
                <div class="price">{{ count_of_clinics }}</div>
            </router-link>
            <router-link :to="{ name: 'newApplications' }">
                Yangi
                <div class="price">{{ new_applications }}</div>
            </router-link>
            <router-link :to="{ name: 'processCorrection' }">
                Tuzzatish jarayonida
                <div class="price">{{ procces_of_correction }}</div>
            </router-link>
            <router-link :to="{ name: 'underReview' }">
                Ko'rib chiqish  jarayonida
                <div class="price">{{ procces_review }}</div>
            </router-link>
            <router-link :to="{ name: 'incomplete' }">
                To'lmagan
                <div class="price">0</div>
            </router-link>
            <router-link :to="{ name: 'processApproval' }">
                Tasdiqlash jarayonida
                <div class="price">{{ procces_confirmation }}</div>
            </router-link>
            <router-link :to="{ name: 'rejected' }">
                Rad etildi
                <div class="price">{{ rejected }}</div>
            </router-link>
            <router-link :to="{ name: 'timeRunningOut' }">
                Mudati o'tmoqda
                <div class="price">{{ is_expiring }}</div>
            </router-link>
            <router-link :to="{ name: 'success' }">
                Muvaffaqiyatli
                <div class="price">{{ successfully }}</div>
            </router-link>
            <router-link :to="{ name: 'negotiationProcess' }">
                Kelishuv jarayonida
                <div class="price">0</div>
            </router-link>
        </div>

        <router-view @counts="getCounts" :prop_item.sync="status" :refresh_item.sync="refresh_status" @after-props="afterProps"/>

        <el-drawer
            :wrapperClosable="false"
            :with-header="false"
            title="I'm outer Drawer"
            :visible.sync="drawerOpen"
            :append-to-body="true"
            size="40%"
            ref="drawerFilter"
            @opened="drawerOpened('drawerFilterChild')"
            >
            <div>
                <myFilter ref="drawerFilterChild" drawer="drawerFilter" @selected-filter="getFilter"/>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import myFilter from "./components/filter";
export default {
    name: "applications",
    components: {
        myFilter,
    },
    data() {
        return {
            drawerOpen: false,
            all_applications: 0,
            count_of_clinics: 0,
            new_applications: 0,
            procces_of_correction: 0,
            procces_review: 0,
            procces_confirmation: 0,
            rejected: 0,
            is_expiring: 0,
            successfully: 0,
            status: false,
            refresh_status: false
        };
    },
    methods: {
        ...mapActions({
            refreshData: 'submitApplications/refreshData',
        }),
        getCounts(data){
            this.all_applications = (data.new + data.procces_confirmation); 
            this.count_of_clinics = data.count_of_clinics;
            this.new_applications = data.new;
            this.procces_of_correction = data.procces_of_correction;
            this.procces_review = data.procces_review;
            this.procces_confirmation = data.procces_confirmation;
            this.rejected = data.rejected;
            this.is_expiring = data.is_expiring;
            this.successfully = data.successfully;
        },
        afterProps(){
            this.status = false;
            this.refresh_status = false;
        },
        getFilter(event){
            if (event) {this.status = event; };
        },
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        refresh(){
            this.refreshData().then(() => { this.refresh_status = true; });
        }
    },
    beforeRouteLeave(to, from, next){
        this.$store.commit("submitApplications/EMPTY_CONFIRMATION");
        next();
    }
};
</script>
