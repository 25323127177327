<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                FILTER
            </div>
            <div>
                <el-button type="success" @click="send()" plain>Jo'natish</el-button>
                <el-button type="warning" @click="close()" plain>
                    Yopish
                </el-button>
            </div>
        </div>
        <div class="bodal-body-my">
            <el-form ref="form" status-icon> 
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="ID">
                            <el-input placeholder="ID" v-model="filterForm.id"></el-input>
                        </el-form-item>

                        <el-form-item  label="Ariza turi">
                            <el-select class="w-100" v-model="filterForm.application_type" placeholder="Ariza turi">
                                <el-option label="Yangi" :value="true"></el-option>
                                <el-option label="Qayta ariza" :value="false"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Kabinet turi">
                            <el-select class="w-100" v-model="filterForm.cabinet_type" placeholder="Kabinet turi">
                                <el-option label="Back office" :value="true"></el-option>
                                <el-option label="Front office" :value="false"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Arizani ko'rib chiqsh vaqti">
                            <el-select class="w-100" v-model="filterForm.review_time_id" placeholder="Arizani ko'rib chiqsh vaqti">
                                <el-option label="Oddiy" :value="1"></el-option>
                                <el-option label="Tezlashtirilgan" :value="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Holati">
                            <el-select class="w-100" v-model="filterForm.filter_condition_id" placeholder="Holati">
                                <el-option label="Yangi" :value="1"></el-option>
                                <el-option label="Ko'rib chiqish jarayonida" :value="2"></el-option>
                                <el-option label="Rad etildi" :value="3"></el-option>
                                <el-option label="Muddati o'tmoqda" :value="4"></el-option>
                                <el-option label="Muvafaqqiyatli" :value="5"></el-option>
                            </el-select>
                        </el-form-item>
                        
                        <div class="w-100">
                            <el-row :gutter="0">
                                <el-col :span="12">
                                    <el-form-item label="Dan">
                                        <el-date-picker 
                                            type="date" 
                                            placeholder="Dan" 
                                            v-model="filterForm.start_date" 
                                            style="width: 100%;"
                                            :format="date_format"
                                            :value-format="date_format"
                                            >
                                            </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="Gacha">
                                        <el-date-picker 
                                            type="date" 
                                            placeholder="Gacha" 
                                            v-model="filterForm.end_date" 
                                            style="width: 100%;"
                                            :format="date_format"
                                            :value-format="date_format"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <el-form-item label="Viloyat">
                            <select-region v-model="filterForm.region_id" :id="filterForm.region_id" size="large" @select-menu-item="selectedRegionId"></select-region>
                        </el-form-item>

                        <el-form-item label="Tuman (shahar)">
                            <el-select
                                v-model="filterForm.city_district_id"
                                placeholder="Tuman (shahar)"
                                filterable
                                clearable
                                class="w-100"
                            >
                                <el-option 
                                    v-for="(city, index) in filtered_city_district"
                                    :key="'city-' + index"
                                    :label="city.name"
                                    :value="city.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import selectRegion from '../../../components/filters/inventory/select-region';
    export default {
        props: ['drawer', 'refresh_item'],
        components: { selectRegion },
        computed: {
            ...mapGetters({
                filtered_city_district: 'region/filtered_city_district',
                filter: 'submitApplications/filter'
            })
        },
        watch: {
            filterForm: {
                handler: async function(newVal){
                    await this.updateFilter(newVal);
                },
                deep: true,
            },
            refresh_item(r_status){
                if (r_status) {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                    this.$emit('selected-filter', true); 
                }
            }
        },
        data() {
            return {
                filterForm: {},
            }
        },
        methods: {
            ...mapActions({
                updateFilter: "submitApplications/updateFilter",
                getCityDistricts: 'region/filteredCityDistrict'
            }),
            send(){
                this.$emit('selected-filter', true); 
                this.close();
            },
            selectedRegionId(region_id){
                if (this.filterForm.city_district_id) {
                    this.filterForm.city_district_id = null;
                }

                if (region_id) {  
                    let query = {region_id: region_id};
                    this.getCityDistricts(query);
                }else{
                    this.$store.commit('region/EMPTY_CITY_DISTRICT');
                }
            },
            close(){                    
                this.parent().closeDrawer(this.drawer);       
            },
            parent() {
                return this.$parent; //.$parent
            },
            opened() {
                this.$store.commit('submitApplications/EMPTY_FILTER');
                this.filterForm = JSON.parse(JSON.stringify(this.filter));
            },
            // closed() {
            //     this.$store.commit('submitApplications/EMPTY_FILTER');
            // },
        }
    }
  </script>